import React from 'react';
import './policies.css';

const TermsAndConditions = () => {
    return (
        <>
            <div className="terms_and_privacy_body">
                <strong>Terms &amp; Conditions</strong> <p>
                By downloading or using the app, these terms will
                automatically apply to you – you should make sure therefore
                that you read them carefully before using the app. You’re not
                allowed to copy or modify the app, any part of the app, or
                our trademarks in any way. You’re not allowed to attempt to
                extract the source code of the app, and you also shouldn’t try
                to translate the app into other languages or make derivative
                versions. The app itself, and all the trademarks, copyright,
                database rights, and other intellectual property rights related
                to it, still belong to Jacob Erceg.
            </p> <p>
                Jacob Erceg is committed to ensuring that the app is
                as useful and efficient as possible. For that reason, we
                reserve the right to make changes to the app or to charge for
                its services, at any time and for any reason. We will never
                charge you for the app or its services without making it very
                clear to you exactly what you’re paying for.
            </p> <p>
                The Todopad app stores and processes personal data that
                you have provided to us, to provide my
                Service. It’s your responsibility to keep your phone and
                access to the app secure. We therefore recommend that you do
                not jailbreak or root your phone, which is the process of
                removing software restrictions and limitations imposed by the
                official operating system of your device. It could make your
                phone vulnerable to malware/viruses/malicious programs,
                compromise your phone’s security features and it could mean
                that the Todopad app won’t work properly or at all.
            </p><p></p>
                <p>
                    Along the same lines, Jacob Erceg cannot always take
                    responsibility for the way you use the app i.e. You need to
                    make sure that your device stays charged – if it runs out of
                    battery and you can’t turn it on to avail the Service,
                    Jacob Erceg cannot accept responsibility.
                </p> <p>
                At some point, we may wish to update the app. The app is
                currently available on iOS – the requirements for the
                system(and for any additional systems we
                decide to extend the availability of the app to) may change,
                and you’ll need to download the updates if you want to keep
                using the app. Jacob Erceg does not promise that it
                will always update the app so that it is relevant to you
                and/or works with the iOS version that you have
                installed on your device. However, you promise to always
                accept updates to the application when offered to you, We may
                also wish to stop providing the app, and may terminate use of
                it at any time without giving notice of termination to you.
                Unless we tell you otherwise, upon any termination, (a) the
                rights and licenses granted to you in these terms will end;
                (b) you must stop using the app, and (if needed) delete it
                from your device.
            </p> <p><strong>Changes to This Terms and Conditions</strong></p> <p>
                I may update our Terms and Conditions
                from time to time. Thus, you are advised to review this page
                periodically for any changes. I will
                notify you of any changes by posting the new Terms and
                Conditions on this page.
            </p> <p>
                These terms and conditions are effective as of 2022-08-20
            </p> <p><strong>Contact Us</strong></p> <p>
                If you have any questions or suggestions about my
                Terms and Conditions, do not hesitate to contact me
                at todopadapp@gmail.com.
            </p> <p>This Terms and Conditions page was generated by <a href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank" rel="noopener noreferrer">App Privacy Policy Generator</a></p>

            </div>
        </>
    )
}

export default TermsAndConditions
