import React from 'react';
import './policies.css';

const PrivacyPolicy = () => {
    return (
        <>
            <div className="terms_and_privacy_body">
                <strong>Privacy Policy</strong> <p>
                Jacob Erceg built the Todopad app as
                a Free app. This SERVICE is provided by
                Jacob Erceg at no cost and is intended for use as
                is.
            </p> <p>
                This page is used to inform visitors regarding my
                policies with the collection, use, and disclosure of Personal
                Information if anyone decided to use my Service.
            </p> <p>
                If you choose to use my Service, then you agree to
                the collection and use of information in relation to this
                policy. The Personal Information that I collect is
                used for providing and improving the Service. I will not use or share your information with
                anyone except as described in this Privacy Policy.
            </p> <p>
                The terms used in this Privacy Policy have the same meanings
                as in our Terms and Conditions, which are accessible at
                Todopad unless otherwise defined in this Privacy Policy.
            </p> <p><strong>Information Collection and Use</strong></p> <p>
                I currently do not collect any information about you at this time. All data is stored on the phone
                itself, and is not sent anywhere over the internet.
            </p> <p><strong>Links to Other Sites</strong></p> <p>
                This Service may contain links to other sites. If you click on
                a third-party link, you will be directed to that site. Note
                that these external sites are not operated by me.
                Therefore, I strongly advise you to review the
                Privacy Policy of these websites. I have
                no control over and assume no responsibility for the content,
                privacy policies, or practices of any third-party sites or
                services.
            </p> <p><strong>Children’s Privacy</strong></p>
                <div><p>
                    I do not knowingly collect personally
                    identifiable information from children. I
                    encourage all children to never submit any personally
                    identifiable information through
                    the Application and/or Services.
                    I encourage parents and legal guardians to monitor
                    their children's Internet usage and to help enforce this Policy by instructing
                    their children never to provide personally identifiable information through the Application and/or
                    Services without their permission. If you have reason to believe that a child
                    has provided personally identifiable information to us through the Application and/or Services,
                    please contact us. You must also be at least 16 years of age to consent to the processing
                    of your personally identifiable information in your country (in some countries we may allow your parent
                    or guardian to do so on your behalf).
                </p></div>
                <p><strong>Changes to This Privacy Policy</strong></p> <p>
                I may update our Privacy Policy from
                time to time. Thus, you are advised to review this page
                periodically for any changes. I will
                notify you of any changes by posting the new Privacy Policy on
                this page.
            </p> <p>This policy is effective as of 2022-08-20</p> <p><strong>Contact Us</strong></p> <p>
                If you have any questions or suggestions about my
                Privacy Policy, do not hesitate to contact me at todopadapp@gmail.com.
            </p> <p>This privacy policy page was created at <a href="https://privacypolicytemplate.net" target="_blank"
                                                               rel="noopener noreferrer">privacypolicytemplate.net </a>and
                modified/generated by <a href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank"
                                         rel="noopener noreferrer">App Privacy Policy Generator</a></p>
            </div>
        </>
    )
}

export default PrivacyPolicy

/*
<!DOCTYPE html>
<html>
<head>
    <meta charset='utf-8'>
    <meta name='viewport' content='width=device-width'>
    <title>TodoPad - Privacy Policy</title>
    <style> body { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding:1em; } </style>
    <link rel="stylesheet" href="policies.css">
</head>
<body>
<strong>Privacy Policy</strong> <p>
    Jacob Erceg built the Todopad app as
    a Free app. This SERVICE is provided by
    Jacob Erceg at no cost and is intended for use as
    is.
</p> <p>
    This page is used to inform visitors regarding my
    policies with the collection, use, and disclosure of Personal
    Information if anyone decided to use my Service.
</p> <p>
    If you choose to use my Service, then you agree to
    the collection and use of information in relation to this
    policy. The Personal Information that I collect is
    used for providing and improving the Service. I will not use or share your information with
    anyone except as described in this Privacy Policy.
</p> <p>
    The terms used in this Privacy Policy have the same meanings
    as in our Terms and Conditions, which are accessible at
    Todopad unless otherwise defined in this Privacy Policy.
</p> <p><strong>Information Collection and Use</strong></p> <p>
    I currently do not collect any information about you at this time. All data is stored on the phone itself, and is not sent anywhere over the internet.
</p> <p><strong>Links to Other Sites</strong></p> <p>
    This Service may contain links to other sites. If you click on
    a third-party link, you will be directed to that site. Note
    that these external sites are not operated by me.
    Therefore, I strongly advise you to review the
    Privacy Policy of these websites. I have
    no control over and assume no responsibility for the content,
    privacy policies, or practices of any third-party sites or
    services.
</p> <p><strong>Children’s Privacy</strong></p> <!----> <div><p>
    I do not knowingly collect personally
    identifiable information from children. I
    encourage all children to never submit any personally
    identifiable information through
    the Application and/or Services.
    I encourage parents and legal guardians to monitor
    their children's Internet usage and to help enforce this Policy by instructing
    their children never to provide personally identifiable information through the Application and/or Services without their permission. If you have reason to believe that a child
    has provided personally identifiable information to us through the Application and/or Services,
    please contact us. You must also be at least 16 years of age to consent to the processing
    of your personally identifiable information in your country (in some countries we may allow your parent
    or guardian to do so on your behalf).
</p></div> <p><strong>Changes to This Privacy Policy</strong></p> <p>
    I may update our Privacy Policy from
    time to time. Thus, you are advised to review this page
    periodically for any changes. I will
    notify you of any changes by posting the new Privacy Policy on
    this page.
</p> <p>This policy is effective as of 2022-08-20</p> <p><strong>Contact Us</strong></p> <p>
    If you have any questions or suggestions about my
    Privacy Policy, do not hesitate to contact me at todopadapp@gmail.com.
</p> <p>This privacy policy page was created at <a href="https://privacypolicytemplate.net" target="_blank" rel="noopener noreferrer">privacypolicytemplate.net </a>and modified/generated by <a href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank" rel="noopener noreferrer">App Privacy Policy Generator</a></p>
</body>
</html>
 */
